// Theme for accessing global style variables with styled components

import colors from '../theme/colors';
import spacing from '../theme/spacing';
import zindex from '../theme/zindex';
import breakpoint from '../theme/breakpoints';

// General site variables
const variables = {
  siteMaxWidth: '1176',
  sitePadding: '88',
  siteMeasure: '700',
}

const theme = { variables, spacing, colors, zindex, breakpoint };
export default theme;
