import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { type } from "../theme/typography";
import theme from "../theme/theme";
import Color from "color";

import Container from "../components/container";

const NavWrapper = styled.nav`
  position: relative;
  z-index: 2;
  pointer-events: none;
  padding: ${theme.spacing.large} 0 ${theme.spacing.large} 0;
  ${theme.breakpoint.down("medium")`
    padding: ${theme.spacing.base} 0;
  `}
  ${theme.breakpoint.down("small")`
    padding: 28px 0;
  `}
  ${type("nav")};
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  li {
    margin-left: ${theme.spacing.small};
    ${theme.breakpoint.down("small")`
      margin-left: 14px;
    `}
  }
  li:first-child {
    flex: 1 1 auto;
    margin: 0;
  }
  a {
    display: inline-block;
    border-color: transparent;
    border-radius: 0;
    color: ${props =>
      Color(props.navColor)
        .fade(0.2)
        .string()};
    text-decoration: none;
    pointer-events: auto;
    &:hover {
      border-radius: 0;
      border-color: ${props =>
        Color(props.navColor)
          .fade(0.4)
          .string()};
    }
    @media (pointer: coarse) {
      &:hover {
        border: none !important;
      }
    }
  }
  a.home {
    color: ${props => props.navColor};
    font-weight: 600;
    letter-spacing: -0.1px;
  }
`;

const NavItem = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

const Nav = props => (
  <NavWrapper {...props}>
    <ul>
      <li>
        <Link className="home" alt="Ryan Lucas" to="/">
          Ryan Lucas
        </Link>
      </li>

      {props.children}
    </ul>
  </NavWrapper>
);

export default props => (
  <Container>
    <Nav {...props}>
      <NavItem to="/work">Work</NavItem>
      <NavItem to="/writing">Writing</NavItem>
      <NavItem to="/about">About</NavItem>
    </Nav>
  </Container>
);
