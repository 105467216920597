import React from "react";
import styled from "styled-components";
import theme from "../theme/theme";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.variables.sitePadding + "px"};
  max-width: ${theme.variables.siteMaxWidth + "px"};
  margin-top: ${props =>
    props.verticalPadding ? props.verticalPadding : "0px"};
  margin-bottom: ${props =>
    props.verticalPadding ? props.verticalPadding : "0px"};
  ${theme.breakpoint.down("medium")`
    padding: 0 ${theme.spacing.xlarge};
  `};
  ${theme.breakpoint.down("small")`
    padding: 0 ${theme.spacing.small};
  `};
`;

export default props => <Container {...props}>{props.children}</Container>;
