// Theme: typography

// Headlines: Tiempos Fine
// Body copy: Tiempos Text
// Nav, Footer, Captions: System fonts

// Import the font files from the file system
import TiemposFineSemibold from "../theme/fonts/TiemposFineWeb-Semibold.woff";
import TiemposTextRegular from "../theme/fonts/TiemposTextWeb-Regular.woff";
import TiemposTextRegularItalic from "../theme/fonts/TiemposTextWeb-RegularItalic.woff";
import TiemposFineSemibold2 from "../theme/fonts/TiemposFineWeb-Semibold.woff2";
import TiemposTextRegular2 from "../theme/fonts/TiemposTextWeb-Regular.woff2";
import TiemposTextRegularItalic2 from "../theme/fonts/TiemposTextWeb-RegularItalic.woff2";
import TiemposHeadlineSemibold from "../theme/fonts/TiemposHeadlineWeb-Semibold.woff";
import TiemposHeadlineSemibold2 from "../theme/fonts/TiemposHeadlineWeb-Semibold.woff2";

// Define type families + metrics
const typeFonts = {
  display: {
    stack: "TiemposFine",
    files: {
      TiemposFineSemibold: {
        url: TiemposFineSemibold,
        urlalt: TiemposFineSemibold2,
        weight: 500,
        style: "regular"
      }
    },
    metrics: {
      capitalHeight: 0.698,
      xHeight: 0.516,
      descender: 0.275,
      ascender: 1.025,
      upm: 1.0,
      lineGap: 0
    }
  },
  text: {
    stack: "TiemposText",
    files: {
      TiemposTextRegular: {
        url: TiemposTextRegular,
        urlalt: TiemposTextRegular2,
        weight: 300,
        style: "regular"
      },
      TiemposTextRegularItalic: {
        url: TiemposTextRegularItalic,
        urlalt: TiemposTextRegularItalic2,
        weight: 300,
        style: "italic"
      },
      TiemposTextSemibold: {
        url: TiemposHeadlineSemibold,
        urlalt: TiemposHeadlineSemibold2,
        weight: 500,
        style: "regular"
      }
    },
    metrics: {
      capitalHeight: 0.698,
      xHeight: 0.516,
      descender: 0.275,
      ascender: 1.025,
      upm: 1.0,
      lineGap: 0
    }
  }
};

// Define typographic scale
const typeScale = {
  footnoteSuperscript: {
    family:
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    size: 13,
    weight: 800
  },
  caption: {
    family:
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    size: 14,
    lineHeight: 20,
    weight: 400
  },
  nav: {
    family:
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    size: 16,
    weight: 400
  },
  abstract: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 17,
    lineHeight: 25,
    weight: 300
  },
  body: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 18,
    lineHeight: 29,
    weight: 300
  },
  bodySmall: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 16,
    lineHeight: 25,
    weight: 300
  },
  workHeader1: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 92,
    lineHeight: 90,
    weight: 500
  },
  workHeader1mobile: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 48,
    lineHeight: 48,
    weight: 500
  },
  writingHeader1: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 48,
    lineHeight: 54,
    weight: 500,
    tracking: 0.4
  },
  writingHeader1mobile: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 46,
    lineHeight: 48,
    weight: 500,
    tracking: 0.4
  },
  header1: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 54,
    lineHeight: 64,
    weight: 500,
    tracking: 0.8
  },
  header1mobile: {
    family: typeFonts["display"].stack + `, 'Times New Roman', Times, serif`,
    size: 38,
    lineHeight: 44,
    weight: 500,
    tracking: 0.8
  },
  header2: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 29,
    lineHeight: 37,
    weight: 500,
    tracking: -0.3
  },
  header2mobile: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 24,
    lineHeight: 30,
    weight: 500,
    tracking: -0.3
  },
  header3: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 22,
    lineHeight: 28,
    weight: 500,
    tracking: -0.1
  },
  header3mobile: {
    family: typeFonts["text"].stack + `, 'Times New Roman', Times, serif`,
    size: 21,
    lineHeight: 28,
    weight: 500,
    tracking: -0.1
  }
};

// Function for declaring global @font-face styles in styled-components
const fontFace = () => {
  let fontString = "";
  for (let font in typeFonts) {
    for (let file in typeFonts[font].files) {
      fontString += `
        @font-face {
          font-family: ${typeFonts[font].stack};
          src: url(${typeFonts[font].files[file].urlalt}) format('woff2'),
               url(${typeFonts[font].files[file].url}) format('woff');
          font-weight: ${typeFonts[font].files[file].weight};
          font-style: ${typeFonts[font].files[file].style};
        }
      `;
    }
  }
  return fontString;
};

// Basic type function for using in styled-components
const type = style => {
  return `
    font-family: ${typeScale[style].family};
    font-size: ${typeScale[style].size}px;
    font-weight: ${typeScale[style].weight};
    line-height: ${typeScale[style].lineHeight}px;
    letter-spacing: ${
      typeScale[style].tracking ? typeScale[style].tracking + `px` : `normal`
    };    
    font-display: swap;
  `;
};

export { typeScale, type, fontFace };

// Scratchpad ideas
// change type scale based on breakpoints
// import { breakpoints } from "../theme/breakpoints"
// for (var breakpoint in breakpoints) {
//  @media screen and (max-width: ${breakpoints[breakpoint]}px) {

// Implement baseline measurement via translate or negative margins?
// + measure-top (false, capheight, xheight)
// + measure-bottom (false, baseline, descender)
// + paragraph-space (space-after, space-before, space-around)
// + paragraph-space-multiplier (float)
