// Theme: Spacing

const spacing  = {
  xxsmall:  '8px',
  xsmall:   '16px',
  small:    '24px',
  base:     '36px',
  large:    '48px',
  xlarge:   '64px',
  xxlarge:  '96px'
}

export default spacing;
