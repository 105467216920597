// Theme: Breakpoints utility for styled-components
//https://github.com/wearehumblebee/styled-components-breakpoint

import styledBreakpoint from '@humblebee/styled-components-breakpoint';

const breakpoint = styledBreakpoint({
  small: 670,
  medium: 880,
  large: 1000
});

export default breakpoint;