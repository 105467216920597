import React from "react";
import styled from "styled-components";
import { type } from "../theme/typography";
import theme from "../theme/theme";
import Color from "color";

import Container from "../components/container";
import HorizontalRule from "../components/horizontal-rule";

const Footer = styled.footer`
  color: ${props => Color(props.footerColor).string()};
  ${type("caption")};
  padding: ${theme.spacing.small} 0 ${theme.spacing.large} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContactLink = styled.a`
  color: ${props => Color(props.footerColor).string()};
  border-color: ${props =>
    Color(props.backgroundColor).isLight()
      ? Color(props.footerColor)
          .fade(0.7)
          .string()
      : Color(props.footerColor)
          .fade(0.5)
          .string()};

  &:hover {
    border-radius: 0px;
    color: ${props =>
      Color(props.backgroundColor).isLight()
        ? Color(props.footerColor)
            .darken(0.3)
            .string()
        : Color(props.footerColor)
            .lighten(0.4)
            .string()};
    border-color: ${props =>
      Color(props.backgroundColor).isLight()
        ? Color(props.footerColor)
            .darken(0.3)
            .string()
        : Color(props.footerColor)
            .lighten(0.4)
            .string()};
  }
`;

const TopLink = styled.a`
  position: relative;
  width: 12px;
  height: 12px;
  border: none;

  // Hacky workaround for :hover:after bug in styled components: https://github.com/styled-components/styled-components/issues/2095
  .arrow {
    width: 12px;
    height: 12px;
    transition: all ease-in-out 150ms; 
    color: transparent;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path fill="${props =>
      Color(props.footerColor)
        .rgb()
        .string()}" d="M12 5.44L6 0 0 5.44 1.5 7 5 3.5V12h2V3.5L10.5 7 12 5.44z"/></svg>') no-repeat center center;
    &:hover {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path fill="${props =>
        Color(props.backgroundColor).isLight()
          ? Color(props.footerColor)
              .darken(0.3)
              .rgb()
              .string()
          : Color(props.footerColor)
              .lighten(0.4)
              .rgb()
              .string()}" d="M12 5.44L6 0 0 5.44 1.5 7 5 3.5V12h2V3.5L10.5 7 12 5.44z"/></svg>') no-repeat center center;
    }
  }
`;

export default props => (
  <Container>
    <HorizontalRule
      margin={theme.spacing.xlarge + " 0 0 0"}
      ruleColor={
        Color(props.backgroundColor).isLight()
          ? Color(props.footerColor)
              .fade(0.8)
              .string()
          : Color(props.footerColor)
              .fade(0.5)
              .string()
      }
    />
    <Footer {...props}>
      <p>
        &copy; {new Date().getFullYear()} ·{" "}
        <ContactLink {...props} href="mailto:ryan@ryanlucas.org">
          Email
        </ContactLink>{" "}
        ·{" "}
        <ContactLink {...props} href="https://twitter.com/ryanlucas/">
          Twitter
        </ContactLink>{" "}
        ·{" "}
        <ContactLink {...props} href="https://github.com/ryanlucas/">
          GitHub
        </ContactLink>
      </p>
      <TopLink {...props} href="#" title="Back to top">
        <div className="arrow">↑</div>
      </TopLink>
    </Footer>
  </Container>
);
