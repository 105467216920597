// Theme: Color palette

const colors = {
  grey: {
    xxdark:    [0,   0,   14],
    xdark:     [0,   0,   24],
    dark:      [0,   0,   51],
    base:      [239, 8,   60],
    light:     [0,   0,   66],
    xlight:    [0,   0,   82],
    xxlight:   [0,   0,   90],
    xxxlight:  [0,   0,   99]
  },
  blue: {
    base:      [264, 70,  92],
    dark:      [300, 18,  70],
    darker:    [239, 30,  24]
    
  }
}

export default colors;
