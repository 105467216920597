import React from "react";
import styled from "styled-components";
import Color from "color";

const HorizontalRule = styled.hr`
  height: 1px;
  border: none;
  background: ${props => Color(props.ruleColor).string()};
  margin: ${props => props.margin ? props.margin : 0};
  width: 100%;
`;

export default props => (
  <HorizontalRule {...props} />
);
