import React from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { Normalize } from "styled-normalize";
import { Helmet } from "react-helmet";

import theme from "../theme/theme";
import { fontFace } from "../theme/typography";

import Nav from "../components/nav";
import Footer from "../components/footer";
import favicon from "../../static/favicon.ico";

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props =>
      props.backgroundColor ? props.backgroundColor : "hsl(232,34%,10%)"};
  }
  
  * , *:before, *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  // Load @font-face rules
  ${fontFace()}

  h1,h2,h3,h4,h5,h6,p,figure {
    margin: 0;
  }

  a {
    text-decoration: none;
    border-width: 0 0 1px 0;
    border-style: solid;
    padding-bottom: 1px;
    transition: background ease-in-out 150ms; 
    &:hover {
      border-radius: 2px;     
    }
  }
`;

const Content = styled.div``;

const Layout = ({
  children,
  location,
  backgroundColor,
  bodyColor,
  footerColor,
  navColor,
  showNav
}) => {
  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <link rel="shortcut icon" href={favicon} />
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover, width=device-width, initial-scale=1, shrink-to-fit=no" /> 
      </Helmet>
      <ThemeProvider theme={theme}>
        <Content>
          <Normalize />
          <GlobalStyle backgroundColor={backgroundColor} />
          { showNav === "false" ? "" : <Nav navColor={navColor} />}
          
          {children}
          <Footer footerColor={footerColor} backgroundColor={backgroundColor} />
        </Content>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Layout;
